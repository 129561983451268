import { env } from '@/config/env';
import { getCookie } from 'cookies-next';
import xior, { XiorRequestConfig, type XiorError } from 'xior';

/**
 * Create and configure your xior instance.
 */
const xiorInstance = xior.create({
  baseURL: env.NEXT_PUBLIC_API_URL, // primary by default
});

/**
 * OPTIONAL: If you want to merge/attach headers automatically,
 * you can do so in a request interceptor or default headers:
 */
xiorInstance.interceptors.request.use(async (config) => {
  const accessToken = await getCookie('access_token');
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  return config;
});

/**
 * Our "drop-in" replacement for your original customFetch.
 *
 * - Returns an object with `{ status, data, headers }`.
 * - Allows you to pass in any fetch-like RequestInit options (now XiorRequestConfig).
 */
export async function customFetch<T = unknown>(
  options: XiorRequestConfig,
  customOptions?: XiorRequestConfig
): Promise<{ status: number; data: T; headers: Headers }> {
  try {
    // Try first with the primary baseURL
    const { data, response, status } = await xiorInstance.request<T>({
      ...options,
      ...customOptions,
    });

    return { status, data, headers: response.headers };
  } catch (error) {
    const err = error as XiorError;
    // Determine if it's a 'network error' or DNS issue, etc.
    const isNetworkError =
      err.name === 'FetchError' || err.message?.includes('NetworkError');

    // Only retry if it's a true network/DNS error
    if (isNetworkError) {
      console.warn('[customFetch] Primary domain failed; trying fallback...');

      // Switch to fallback baseURL
      xiorInstance.defaults.baseURL = env.NEXT_PUBLIC_API_FALLBACK_URL;

      // Retry the request once with fallback
      const { data, response, status } = await xiorInstance.request<T>({
        ...options,
        ...customOptions,
      });

      return { status, data, headers: response.headers };
    }

    // Otherwise re-throw the error so the caller can handle it
    throw error;
  }
}

export default customFetch;
