import type { RssFeed } from '@/api/backoffice-schemas';
import CsIcon from '@public/icons/games/cs.svg';
import DotaIcon from '@public/icons/games/dota.svg';
import Signal from '@public/icons/signal.svg';
import DOMPurify from 'isomorphic-dompurify';
import { marked } from 'marked';
import moment from 'moment-timezone';
import Link from 'next/link';
import parse = marked.parse;

interface NewsFeedCardProps {
  data: RssFeed;
}

const NewsFeedCard = ({ data }: NewsFeedCardProps) => {
  const { link, date, title, game, description, source } = data;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <Link
      href={link || ''}
      target="_blank"
      className="flex flex-col gap-[0.75rem] bg-light-gray rounded-lg hover:bg-light-blue-o15"
    >
      <div className="p-3 flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between">
          <p className="font-semibold text-xs leading-[14px] ">
            {moment(date).tz(userTimeZone).format('DD.MM.YYYY')}
          </p>
          {game === 'Dota' ? (
            <div className="flex flex-row gap-3">
              <DotaIcon className={'text-red w-4'} />
              <Signal className={'text-red w-4'} />
            </div>
          ) : (
            <div className="flex flex-row gap-3">
              <CsIcon className={'text-orange w-4'} />
              <Signal className={'text-orange w-4'} />
            </div>
          )}
        </div>
        <div className="flex flex-col font-normal text-sm leading-[17px] text-gray gap-2">
          <div
            className="flex flex-col gap-2 font-semibold text-sm leading-[17px]"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parse(title || '') as string, {
                USE_PROFILES: { html: true },
              }),
            }}
          />
          <div
            className="flex flex-col gap-2 font-normal text-sm leading-[17px]"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                parse((description || '').replace(/<img[^>]*>/g, '')) as string,
                {
                  USE_PROFILES: { html: true },
                }
              ),
            }}
          />
          <p className="font-normal text-xs leading-[14px]">
            Source: <span className="text-blue-gray">{source}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default NewsFeedCard;
