'use client';

import { signInAction } from '@/actions/sign-in.action';
import type { CreatePlayerCommand } from '@/api/backoffice-schemas';
import { usePostApiPlayers } from '@/api/backoffice/players/players';
import { Button } from '@/components/button';
import { Input } from '@/components/Input/Input';
import { useAuthDialog } from '@/context/AuthDialogProvider';
import { LockClosedIcon, UserIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import PlayerAvatar from '@public/icons/player-avatar.svg';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

// Zod validation schema
const registerSchema = z
  .object({
    email: z.string().min(1, 'Email is required').email('Invalid email format'),
    password: z
      .string()
      .min(8, 'Password must be at least 8 characters')
      .min(1, 'Password is required'),
    passwordAgain: z.string().min(1, 'Confirm Password is required'),
    username: z.string().min(1, 'Nickname is required'),
  })
  .refine((data) => data.password === data.passwordAgain, {
    message: 'Passwords must match',
    path: ['passwordAgain'],
  });

export const SignUpForm = () => {
  const { toggleIsOpen } = useAuthDialog();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<CreatePlayerCommand>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      email: '',
      password: '',
      passwordAgain: '',
      username: '',
    },
  });

  const { mutateAsync: signUpAction } = usePostApiPlayers();

  const onSubmit = async (data: CreatePlayerCommand) => {
    try {
      await signUpAction({ data });
      await signInAction(data);
      toggleIsOpen();
    } catch (error) {
      console.error(error);
      setError('email', {
        type: 'manual',
        message: 'Email is already taken',
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-col gap-2" onClick={(e) => e.stopPropagation()}>
        <div className="self-center flex flex-col gap-3 w-full max-w-[480px]">
          <h2 className="text-2xl font-semibold">Create an GOCORE account</h2>
          <p className="hidden md:block text-base font-normal leading-6 mb-2">
            Showoff your knowledge in the GOCORE community. Climb through the
            leader boards and become the best tipster.
          </p>
        </div>

        <div className="self-center flex flex-col gap-5 w-full max-w-[480px]">
          <div className="flex flex-col gap-2 w-full">
            <Input
              {...register('email')}
              type="text"
              leftIcon={<UserIcon className="size-5" />}
              placeholder="Your email"
              label="Email"
              error={errors.email?.message}
            />

            <Input
              {...register('password')}
              type="password"
              leftIcon={<LockClosedIcon className="size-5" />}
              placeholder="Your password"
              label="Password"
              error={errors.password?.message}
            />

            <Input
              {...register('passwordAgain')}
              type="password"
              leftIcon={<LockClosedIcon className="size-5" />}
              placeholder="Confirm Password"
              label="Password again"
              error={errors.passwordAgain?.message}
            />
          </div>

          <div>
            <Input
              {...register('username')}
              label="Username"
              type="text"
              leftIcon={<PlayerAvatar className="size-5" />}
              placeholder="Username"
              error={errors.username?.message}
              wrapperClassName="mb-1"
            />

            <div className="text-sm">
              Used to display on tips, pickems and leaderboards
            </div>
          </div>

          <Button
            type="submit"
            variant="primary"
            size="lg"
            disabled={isSubmitting}
            onClick={(e) => e.stopPropagation()}
          >
            Register
          </Button>
        </div>
      </div>
    </form>
  );
};
