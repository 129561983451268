import { Game } from '@/enums';
import type { StrapiGame, StrapiVideoGame } from '@t/strapi';

export const getGameFromStrapiGame = (game: StrapiGame): Game => {
  switch (game) {
    case 'CS':
      return Game.CSGO;
    case 'Dota':
      return Game.DOTA;
  }
};

export const getGameFromStrapiVideoGame = (
  game: StrapiVideoGame
): Game | null => {
  switch (game) {
    case 'cs-go':
      return Game.CSGO;
    case 'dota-2':
      return Game.DOTA;
    default:
      return null;
  }
};
