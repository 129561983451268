import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin-ext\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer","Bounce"] */ "/vercel/path0/node_modules/.pnpm/react-toastify@11.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-toastify/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-toastify@11.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/vercel/path0/src/components/providers/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthDialogProvider"] */ "/vercel/path0/src/context/AuthDialogProvider.tsx");
