/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Game studio API
 * Game studio API documentation OAS.
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ChangeUserDetailCommand,
  CreateUserCommand,
  GetApiBackOfficeUsersFulltextParams,
  GetApiBackOfficeUsersParams,
  IdentityRole,
  ModifyUserRolesCommand,
  UserDetailDto,
  UserDtoPaginatedList
} from '../../backoffice-schemas'
import getApiBackOfficeUsersMutator from '../../../lib/customInstance';
import postApiBackOfficeUsersMutator from '../../../lib/customInstance';
import putApiBackOfficeUsersMutator from '../../../lib/customInstance';
import getApiBackOfficeUsersFulltextMutator from '../../../lib/customInstance';
import getApiBackOfficeUsersIdMutator from '../../../lib/customInstance';
import getApiBackOfficeUsersRolesMutator from '../../../lib/customInstance';
import putApiBackOfficeUsersIdLockMutator from '../../../lib/customInstance';
import putApiBackOfficeUsersIdUnlockMutator from '../../../lib/customInstance';
import getApiBackOfficeUsersCurrentUserDetailMutator from '../../../lib/customInstance';
import putApiBackOfficeUsersCurrentUserChangeDetailMutator from '../../../lib/customInstance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getApiBackOfficeUsers = (
    params?: GetApiBackOfficeUsersParams,
 options?: SecondParameter<typeof getApiBackOfficeUsersMutator>,signal?: AbortSignal
) => {
      
      
      return getApiBackOfficeUsersMutator<UserDtoPaginatedList>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiBackOfficeUsersQueryKey = (params?: GetApiBackOfficeUsersParams,) => {
    return [`https://api.dev.gocore.gg/api/BackOfficeUsers`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiBackOfficeUsersQueryOptions = <TData = Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError = unknown>(params?: GetApiBackOfficeUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiBackOfficeUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBackOfficeUsers>>> = ({ signal }) => getApiBackOfficeUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiBackOfficeUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBackOfficeUsers>>>
export type GetApiBackOfficeUsersQueryError = unknown


export function useGetApiBackOfficeUsers<TData = Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError = unknown>(
 params: undefined |  GetApiBackOfficeUsersParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsers<TData = Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError = unknown>(
 params?: GetApiBackOfficeUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsers<TData = Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError = unknown>(
 params?: GetApiBackOfficeUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiBackOfficeUsers<TData = Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError = unknown>(
 params?: GetApiBackOfficeUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsers>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiBackOfficeUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postApiBackOfficeUsers = (
    createUserCommand: CreateUserCommand,
 options?: SecondParameter<typeof postApiBackOfficeUsersMutator>,signal?: AbortSignal
) => {
      
      
      return postApiBackOfficeUsersMutator<UserDetailDto>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createUserCommand, signal
    },
      options);
    }
  


export const getPostApiBackOfficeUsersMutationOptions = <TData = Awaited<ReturnType<typeof postApiBackOfficeUsers>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateUserCommand}, TContext>, request?: SecondParameter<typeof postApiBackOfficeUsersMutator>}
) => {
const mutationKey = ['postApiBackOfficeUsers'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiBackOfficeUsers>>, {data: CreateUserCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiBackOfficeUsers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: CreateUserCommand}, TContext>}

    export type PostApiBackOfficeUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiBackOfficeUsers>>>
    export type PostApiBackOfficeUsersMutationBody = CreateUserCommand
    export type PostApiBackOfficeUsersMutationError = unknown

    export const usePostApiBackOfficeUsers = <TData = Awaited<ReturnType<typeof postApiBackOfficeUsers>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateUserCommand}, TContext>, request?: SecondParameter<typeof postApiBackOfficeUsersMutator>}
): UseMutationResult<
        TData,
        TError,
        {data: CreateUserCommand},
        TContext
      > => {

      const mutationOptions = getPostApiBackOfficeUsersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putApiBackOfficeUsers = (
    modifyUserRolesCommand: ModifyUserRolesCommand,
 options?: SecondParameter<typeof putApiBackOfficeUsersMutator>,) => {
      
      
      return putApiBackOfficeUsersMutator<UserDetailDto>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: modifyUserRolesCommand
    },
      options);
    }
  


export const getPutApiBackOfficeUsersMutationOptions = <TData = Awaited<ReturnType<typeof putApiBackOfficeUsers>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ModifyUserRolesCommand}, TContext>, request?: SecondParameter<typeof putApiBackOfficeUsersMutator>}
) => {
const mutationKey = ['putApiBackOfficeUsers'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiBackOfficeUsers>>, {data: ModifyUserRolesCommand}> = (props) => {
          const {data} = props ?? {};

          return  putApiBackOfficeUsers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: ModifyUserRolesCommand}, TContext>}

    export type PutApiBackOfficeUsersMutationResult = NonNullable<Awaited<ReturnType<typeof putApiBackOfficeUsers>>>
    export type PutApiBackOfficeUsersMutationBody = ModifyUserRolesCommand
    export type PutApiBackOfficeUsersMutationError = unknown

    export const usePutApiBackOfficeUsers = <TData = Awaited<ReturnType<typeof putApiBackOfficeUsers>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ModifyUserRolesCommand}, TContext>, request?: SecondParameter<typeof putApiBackOfficeUsersMutator>}
): UseMutationResult<
        TData,
        TError,
        {data: ModifyUserRolesCommand},
        TContext
      > => {

      const mutationOptions = getPutApiBackOfficeUsersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getApiBackOfficeUsersFulltext = (
    params?: GetApiBackOfficeUsersFulltextParams,
 options?: SecondParameter<typeof getApiBackOfficeUsersFulltextMutator>,signal?: AbortSignal
) => {
      
      
      return getApiBackOfficeUsersFulltextMutator<UserDtoPaginatedList>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers/fulltext`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiBackOfficeUsersFulltextQueryKey = (params?: GetApiBackOfficeUsersFulltextParams,) => {
    return [`https://api.dev.gocore.gg/api/BackOfficeUsers/fulltext`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiBackOfficeUsersFulltextQueryOptions = <TData = Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError = unknown>(params?: GetApiBackOfficeUsersFulltextParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersFulltextMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiBackOfficeUsersFulltextQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>> = ({ signal }) => getApiBackOfficeUsersFulltext(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiBackOfficeUsersFulltextQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>>
export type GetApiBackOfficeUsersFulltextQueryError = unknown


export function useGetApiBackOfficeUsersFulltext<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError = unknown>(
 params: undefined |  GetApiBackOfficeUsersFulltextParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersFulltextMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsersFulltext<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError = unknown>(
 params?: GetApiBackOfficeUsersFulltextParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersFulltextMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsersFulltext<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError = unknown>(
 params?: GetApiBackOfficeUsersFulltextParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersFulltextMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiBackOfficeUsersFulltext<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError = unknown>(
 params?: GetApiBackOfficeUsersFulltextParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersFulltext>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersFulltextMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiBackOfficeUsersFulltextQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiBackOfficeUsersId = (
    id: string,
 options?: SecondParameter<typeof getApiBackOfficeUsersIdMutator>,signal?: AbortSignal
) => {
      
      
      return getApiBackOfficeUsersIdMutator<UserDetailDto>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiBackOfficeUsersIdQueryKey = (id: string,) => {
    return [`https://api.dev.gocore.gg/api/BackOfficeUsers/${id}`] as const;
    }

    
export const getGetApiBackOfficeUsersIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError = unknown>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersIdMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiBackOfficeUsersIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBackOfficeUsersId>>> = ({ signal }) => getApiBackOfficeUsersId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiBackOfficeUsersIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBackOfficeUsersId>>>
export type GetApiBackOfficeUsersIdQueryError = unknown


export function useGetApiBackOfficeUsersId<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError = unknown>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsersId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersIdMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsersId<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsersId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsersId<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiBackOfficeUsersId<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersId>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiBackOfficeUsersIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiBackOfficeUsersRoles = (
    
 options?: SecondParameter<typeof getApiBackOfficeUsersRolesMutator>,signal?: AbortSignal
) => {
      
      
      return getApiBackOfficeUsersRolesMutator<IdentityRole[]>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers/roles`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiBackOfficeUsersRolesQueryKey = () => {
    return [`https://api.dev.gocore.gg/api/BackOfficeUsers/roles`] as const;
    }

    
export const getGetApiBackOfficeUsersRolesQueryOptions = <TData = Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersRolesMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiBackOfficeUsersRolesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>> = ({ signal }) => getApiBackOfficeUsersRoles(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiBackOfficeUsersRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>>
export type GetApiBackOfficeUsersRolesQueryError = unknown


export function useGetApiBackOfficeUsersRoles<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersRolesMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsersRoles<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersRolesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsersRoles<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersRolesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiBackOfficeUsersRoles<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersRoles>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersRolesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiBackOfficeUsersRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putApiBackOfficeUsersIdLock = (
    id: string,
 options?: SecondParameter<typeof putApiBackOfficeUsersIdLockMutator>,) => {
      
      
      return putApiBackOfficeUsersIdLockMutator<UserDetailDto>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers/${id}/lock`, method: 'PUT'
    },
      options);
    }
  


export const getPutApiBackOfficeUsersIdLockMutationOptions = <TData = Awaited<ReturnType<typeof putApiBackOfficeUsersIdLock>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, request?: SecondParameter<typeof putApiBackOfficeUsersIdLockMutator>}
) => {
const mutationKey = ['putApiBackOfficeUsersIdLock'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiBackOfficeUsersIdLock>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  putApiBackOfficeUsersIdLock(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string}, TContext>}

    export type PutApiBackOfficeUsersIdLockMutationResult = NonNullable<Awaited<ReturnType<typeof putApiBackOfficeUsersIdLock>>>
    
    export type PutApiBackOfficeUsersIdLockMutationError = unknown

    export const usePutApiBackOfficeUsersIdLock = <TData = Awaited<ReturnType<typeof putApiBackOfficeUsersIdLock>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, request?: SecondParameter<typeof putApiBackOfficeUsersIdLockMutator>}
): UseMutationResult<
        TData,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getPutApiBackOfficeUsersIdLockMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putApiBackOfficeUsersIdUnlock = (
    id: string,
 options?: SecondParameter<typeof putApiBackOfficeUsersIdUnlockMutator>,) => {
      
      
      return putApiBackOfficeUsersIdUnlockMutator<UserDetailDto>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers/${id}/unlock`, method: 'PUT'
    },
      options);
    }
  


export const getPutApiBackOfficeUsersIdUnlockMutationOptions = <TData = Awaited<ReturnType<typeof putApiBackOfficeUsersIdUnlock>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, request?: SecondParameter<typeof putApiBackOfficeUsersIdUnlockMutator>}
) => {
const mutationKey = ['putApiBackOfficeUsersIdUnlock'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiBackOfficeUsersIdUnlock>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  putApiBackOfficeUsersIdUnlock(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{id: string}, TContext>}

    export type PutApiBackOfficeUsersIdUnlockMutationResult = NonNullable<Awaited<ReturnType<typeof putApiBackOfficeUsersIdUnlock>>>
    
    export type PutApiBackOfficeUsersIdUnlockMutationError = unknown

    export const usePutApiBackOfficeUsersIdUnlock = <TData = Awaited<ReturnType<typeof putApiBackOfficeUsersIdUnlock>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string}, TContext>, request?: SecondParameter<typeof putApiBackOfficeUsersIdUnlockMutator>}
): UseMutationResult<
        TData,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getPutApiBackOfficeUsersIdUnlockMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getApiBackOfficeUsersCurrentUserDetail = (
    
 options?: SecondParameter<typeof getApiBackOfficeUsersCurrentUserDetailMutator>,signal?: AbortSignal
) => {
      
      
      return getApiBackOfficeUsersCurrentUserDetailMutator<UserDetailDto>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers/current-user-detail`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiBackOfficeUsersCurrentUserDetailQueryKey = () => {
    return [`https://api.dev.gocore.gg/api/BackOfficeUsers/current-user-detail`] as const;
    }

    
export const getGetApiBackOfficeUsersCurrentUserDetailQueryOptions = <TData = Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersCurrentUserDetailMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiBackOfficeUsersCurrentUserDetailQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>> = ({ signal }) => getApiBackOfficeUsersCurrentUserDetail(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiBackOfficeUsersCurrentUserDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>>
export type GetApiBackOfficeUsersCurrentUserDetailQueryError = unknown


export function useGetApiBackOfficeUsersCurrentUserDetail<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersCurrentUserDetailMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsersCurrentUserDetail<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiBackOfficeUsersCurrentUserDetailMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiBackOfficeUsersCurrentUserDetail<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersCurrentUserDetailMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiBackOfficeUsersCurrentUserDetail<TData = Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBackOfficeUsersCurrentUserDetail>>, TError, TData>>, request?: SecondParameter<typeof getApiBackOfficeUsersCurrentUserDetailMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiBackOfficeUsersCurrentUserDetailQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putApiBackOfficeUsersCurrentUserChangeDetail = (
    changeUserDetailCommand: ChangeUserDetailCommand,
 options?: SecondParameter<typeof putApiBackOfficeUsersCurrentUserChangeDetailMutator>,) => {
      
      
      return putApiBackOfficeUsersCurrentUserChangeDetailMutator<UserDetailDto>(
      {url: `https://api.dev.gocore.gg/api/BackOfficeUsers/current-user-change-detail`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: changeUserDetailCommand
    },
      options);
    }
  


export const getPutApiBackOfficeUsersCurrentUserChangeDetailMutationOptions = <TData = Awaited<ReturnType<typeof putApiBackOfficeUsersCurrentUserChangeDetail>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ChangeUserDetailCommand}, TContext>, request?: SecondParameter<typeof putApiBackOfficeUsersCurrentUserChangeDetailMutator>}
) => {
const mutationKey = ['putApiBackOfficeUsersCurrentUserChangeDetail'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiBackOfficeUsersCurrentUserChangeDetail>>, {data: ChangeUserDetailCommand}> = (props) => {
          const {data} = props ?? {};

          return  putApiBackOfficeUsersCurrentUserChangeDetail(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: ChangeUserDetailCommand}, TContext>}

    export type PutApiBackOfficeUsersCurrentUserChangeDetailMutationResult = NonNullable<Awaited<ReturnType<typeof putApiBackOfficeUsersCurrentUserChangeDetail>>>
    export type PutApiBackOfficeUsersCurrentUserChangeDetailMutationBody = ChangeUserDetailCommand
    export type PutApiBackOfficeUsersCurrentUserChangeDetailMutationError = unknown

    export const usePutApiBackOfficeUsersCurrentUserChangeDetail = <TData = Awaited<ReturnType<typeof putApiBackOfficeUsersCurrentUserChangeDetail>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ChangeUserDetailCommand}, TContext>, request?: SecondParameter<typeof putApiBackOfficeUsersCurrentUserChangeDetailMutator>}
): UseMutationResult<
        TData,
        TError,
        {data: ChangeUserDetailCommand},
        TContext
      > => {

      const mutationOptions = getPutApiBackOfficeUsersCurrentUserChangeDetailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    