import { cn } from '@/utils/cn';
import { cva, type VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from 'react';

const buttonVariants = cva(
  [
    'flex items-center gap-2 justify-center border border-transparent rounded-lg font-semibold text-center transition-all',
    'cursor-pointer',
    'disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-slate-400',
  ],
  {
    variants: {
      variant: {
        default: 'text-white bg-light-gray hover:bg-light-gray/70',
        none: 'border-blue-gray text-blue-gray bg-component-bg border-solid hover:bg-blue-gray/5',
        primary:
          'bg-light-blue border-light-blue text-black border-solid hover:bg-light-blue/90 hover:border-light-blue/90',
        secondary:
          'border-blue-gray text-blue-gray bg-component-bg border-solid hover:bg-blue-gray/5',
        ghost: 'hover:bg-light-gray',
        outline:
          'border border-blue-gray text-blue-gray hover:bg-blue-gray/10 border-solid',
      },
      size: {
        sm: 'text-sm px-3 h-8',
        md: 'text-sm px-4 h-9',
        lg: 'text-base px-6 h-12',
      },
      square: {
        true: '',
        false: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
    compoundVariants: [
      {
        size: 'sm',
        square: true,
        class: 'p-0 size-8',
      },
      {
        size: 'md',
        square: true,
        class: 'p-0 size-9',
      },
      {
        size: 'lg',
        square: true,
        class: 'p-0 size-12',
      },
    ],
  }
);

type ButtonBaseProps = VariantProps<typeof buttonVariants>;

type ButtonAsButtonProps = ButtonBaseProps & {
  as?: 'button';
  href?: never;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonBaseProps>;

type ButtonAsLinkProps = ButtonBaseProps & {
  as: 'link';
  href: string;
} & Omit<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    keyof ButtonBaseProps | 'href'
  >;

type ButtonProps = ButtonAsButtonProps | ButtonAsLinkProps;

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    { as = 'button', className, variant, size, square, children, ...props },
    ref
  ) => {
    const classes = cn(buttonVariants({ variant, size, className, square }));

    if (as === 'link') {
      const { href, ...linkProps } = props as ButtonAsLinkProps;
      return (
        <Link
          href={href}
          className={classes}
          ref={ref as React.Ref<HTMLAnchorElement>}
          {...linkProps}
        >
          {children}
        </Link>
      );
    }

    return (
      <button
        className={classes}
        ref={ref as React.Ref<HTMLButtonElement>}
        {...(props as ButtonAsButtonProps)}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export { Button, buttonVariants };
export type { ButtonProps };
