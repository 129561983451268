'use client';

import { Game } from '@/enums';
import CSIcon from '@public/icons/games/cs.svg';
import DotaIcon from '@public/icons/games/dota.svg';
import InstagramIcon from '@public/icons/socials/instagram.svg';
import TikTokIcon from '@public/icons/socials/tiktok.svg';
import TwitterIcon from '@public/icons/socials/twitter.svg';
import YoutubeIcon from '@public/icons/socials/youtube.svg';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { GoCoreLogo } from '../logo';

// Helper functions from NavigationMenu
const extractGamePrefix = (pathname: string): string | null => {
  const gamePrefixMatch = pathname.match(/^\/([^\/]+)/);
  if (
    gamePrefixMatch &&
    Object.values(Game).includes(gamePrefixMatch[1] as Game)
  ) {
    return gamePrefixMatch[1];
  }
  return null;
};

const generateHrefToNavigateTo = (
  currentPath: string,
  targetHref: string,
  matchGamePrefix: boolean
): string => {
  if (!matchGamePrefix) return targetHref;

  const gamePrefix = extractGamePrefix(currentPath);
  if (gamePrefix) {
    if (targetHref === '/') {
      return `/${gamePrefix}`;
    }
    return `/${gamePrefix}${targetHref}`;
  }

  return targetHref;
};

const navigationFooter = {
  quicklinks: [
    { name: 'Home', href: '/', matchGamePrefix: true },
    { name: 'News', href: '/news', matchGamePrefix: true },
    { name: 'Matches', href: '/matches', matchGamePrefix: true },
    { name: 'Betting HUB', href: '/hub', matchGamePrefix: false },
    { name: 'Tips', href: '/tips', matchGamePrefix: false },
    { name: "Pick'ems", href: '/pickems', matchGamePrefix: true },
    {
      name: 'Contact us',
      href: 'mailto:info@gocore.gg',
      matchGamePrefix: false,
    },
  ],
  games: [
    {
      icon: <DotaIcon className="w-6 h-6 text-red" />,
      href: `/${Game.DOTA}`,
      name: 'DOTA',
      game: Game.DOTA,
    },
    {
      icon: <CSIcon className="w-6 h-6 text-orange" />,
      href: `/${Game.CSGO}`,
      name: 'CS',
      game: Game.CSGO,
    },
  ],
  legal: [
    { name: 'Privacy policy', href: '/privacy', matchGamePrefix: false },
    {
      name: 'Use of Services',
      href: '/terms-of-service',
      matchGamePrefix: false,
    },
    { name: 'Cookie policy', href: '/cookies', matchGamePrefix: false },
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://x.com',
      icon: TwitterIcon,
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com',
      icon: YoutubeIcon,
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com',
      icon: InstagramIcon,
    },
    {
      name: 'TikTok',
      href: 'https://www.tiktok.com',
      icon: () => <TikTokIcon />,
    },
  ],
};

const Footer = () => {
  const pathname = usePathname();

  return (
    <footer className="bg-dark-gray w-full" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="container mx-auto max-w-screen-xl">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8 p-4 ">
          <div className="space-y-8">
            <GoCoreLogo />

            <div className="flex space-x-6">
              {navigationFooter.social.map(
                ({ href, icon: Icon, name }, index) => (
                  <Link
                    target="_blank"
                    key={index}
                    href={href}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">{name}</span>
                    <Icon className="h-6 w-6" aria-hidden="true" />
                  </Link>
                )
              )}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:col-span-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-blue-gray">
                  Quicklinks
                </h3>
                <div className="grid grid-cols-2 grid-rows-3 mt-4 gap-2">
                  {navigationFooter.quicklinks.map(
                    ({ name, href, matchGamePrefix = false }, index) => (
                      <Link
                        key={index}
                        href={generateHrefToNavigateTo(
                          pathname,
                          href,
                          matchGamePrefix
                        )}
                        className="text-sm leading-6 text-gray hover:text-white"
                      >
                        {name}
                      </Link>
                    )
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-blue-gray">
                    Legal
                  </h3>
                  <ul role="list" className="mt-4 space-y-2">
                    {navigationFooter.legal.map(
                      ({ name, href, matchGamePrefix = false }, index) => (
                        <li key={index}>
                          <Link
                            href={generateHrefToNavigateTo(
                              pathname,
                              href,
                              matchGamePrefix
                            )}
                            className="text-sm leading-6 text-gray hover:text-white"
                          >
                            {name}
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-blue-gray">
                    Games
                  </h3>
                  <ul role="list" className="grid grid-cols-3 mt-2 w-[152px]">
                    {navigationFooter.games.map(({ href, icon }, index) => (
                      <li key={index}>
                        <Link
                          href={href}
                          className="flex scale-[2] mt-4 justify-center items-center"
                        >
                          {icon}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-light-blue p-4 mt-2 text-gray">
          <p className="text-sm leading-5 text-gray font-light flex flex-col gap-1">
            <span className="font-semibold">GOCORE.GG</span>
            <span>
              Gambling involves risk. Please only gamble with funds that you can
              comfortably afford to lose. Whilst we do our upmost to offer good
              advice and information we cannot be held responsible for any loss
              that maybe be incurred as a result of gambling.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
