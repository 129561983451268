import { Game } from '@/enums';
import CSIcon from '@public/icons/games/cs.svg';
import DotaIcon from '@public/icons/games/dota.svg';
import type { ReactNode } from 'react';

/** Extracts the game prefix (e.g. "dota" or "csgo") if present in the path. */
export const extractGamePrefix = (pathname: string): string | null => {
  const match = pathname.match(/^\/([^/]+)/);
  if (match && Object.values(Game).includes(match[1] as Game)) {
    return match[1];
  }
  return null;
};

/** For a path like "/news", generate ["/news", "/dota/news", "/csgo/news", ...]. */
export const generatePathsToCheck = (pathToCheck: string): string[] => {
  const gameValues = Object.values(Game);
  const paths: string[] = [];

  // If the nav item is root "/", return ["/", "/dota", "/csgo", ...]
  if (pathToCheck === '/') {
    return [...gameValues.map((g) => `/${g}`), pathToCheck];
  }

  // Otherwise prepend each game to the path
  for (const g of gameValues) {
    paths.push(`/${g}${pathToCheck}`);
  }

  return [pathToCheck, ...paths];
};

/** Which paths represent "root"?  e.g. ["/", "/dota", "/csgo"] */
export const generateRootPathsToCheck = (): string[] => {
  const gameValues = Object.values(Game);
  return [...gameValues.map((g) => `/${g}`), '/'];
};

export interface NavItem {
  id?: string;
  name: string;
  href: string;
  matchGamePrefix?: boolean;
  icon?: ReactNode;
  subLinks?: NavItem[];
}

/**
 * Determines if `pathname` is considered active for a given nav item.
 * Uses the same root-match and prefix-match logic as in the desktop menu.
 */
export const isPathActive = (pathname: string, item: NavItem): boolean => {
  const { matchGamePrefix, href } = item;
  const pathsToCheck = matchGamePrefix ? generatePathsToCheck(href) : [href];
  const rootPaths = generateRootPathsToCheck();

  // If the nav item is root ("/", "/dota", "/csgo"), match exactly
  if (rootPaths.includes(href)) {
    return pathname === href || rootPaths.includes(pathname);
  }

  // Otherwise, see if pathname matches or starts with (e.g. "/news" or "/news/...")
  return pathsToCheck.some((p) =>
    p === '/'
      ? pathname === '/'
      : pathname === p || pathname.startsWith(`${p}/`)
  );
};

/**
 * Given a current path (like "/dota/news"), a target link ("/news"), and a
 * flag to preserve the prefix, generate the correct href ("/dota/news").
 */
export const generateHrefToNavigateTo = (
  currentPath: string,
  targetHref: string,
  matchGamePrefix?: boolean
): string => {
  if (!matchGamePrefix) return targetHref;

  const prefix = extractGamePrefix(currentPath);
  if (!prefix) return targetHref;

  // If target is "/", then final href is just "/dota" (if prefix === "dota"), etc.
  if (targetHref === '/') {
    return `/${prefix}`;
  }
  return `/${prefix}${targetHref}`;
};

export const NAVIGATION_DATA: NavItem[] = [
  { id: 'news', name: 'News', href: '/news', matchGamePrefix: true },
  { id: 'matches', name: 'Matches', href: '/matches', matchGamePrefix: true },
  {
    id: 'betting-hub',
    name: 'Betting HUB',
    href: '/hub',
    subLinks: [
      { id: 'bookmakers', name: 'Bookmakers', href: '/hub' },
      { id: 'bonuses', name: 'Bonuses', href: '/hub/bonuses' },
    ],
  },
  { id: 'tips', name: 'Tips', href: '/tips' },
  {
    id: 'pickems',
    name: "Pick'ems",
    href: '/pickems',
    matchGamePrefix: true,
    subLinks: [
      {
        id: 'dota2-pickems',
        name: 'Dota 2',
        href: `/${Game.DOTA}/pickems`,
        icon: <DotaIcon className="w-4 text-red" />,
      },
      {
        id: 'csgo2-pickems',
        name: 'Counter-Strike 2',
        href: `/${Game.CSGO}/pickems`,
        icon: <CSIcon className="w-4 text-orange" />,
      },
    ],
  },
];
