'use client';
import { cn } from '@/utils/cn';
import { TZDate } from '@date-fns/tz';
import { addDays, format, startOfDay, subDays } from 'date-fns';
import { FC, useMemo, useState } from 'react';

interface ICalendarProps {
  onChange: (date: TZDate) => void;
  type?: 'past' | 'future';
}

interface DateItem {
  label: string;
  date: string;
  fullDate: TZDate;
}

const Calendar: FC<ICalendarProps> = ({ onChange, type = 'future' }) => {
  // Generate dates once using useMemo
  const dates = useMemo((): DateItem[] => {
    const today = new TZDate(startOfDay(new Date()));

    if (type === 'future') {
      return Array.from({ length: 5 }, (_, index) => {
        const date =
          index === 0 ? today : new TZDate(startOfDay(addDays(today, index)));
        return {
          label: index === 0 ? 'TODAY' : format(date, 'EEE'),
          date: format(date, 'd MMM'),
          fullDate: date,
        };
      });
    } else {
      return Array.from({ length: 5 }, (_, index) => {
        const daysFromEnd = 4 - index;
        const date =
          daysFromEnd === 0
            ? today
            : new TZDate(startOfDay(subDays(today, daysFromEnd)));
        return {
          label: daysFromEnd === 0 ? 'TODAY' : format(date, 'EEE'),
          date: format(date, 'd MMM'),
          fullDate: date,
        };
      });
    }
  }, [type]);

  const [activeDate, setActiveDate] = useState<string>(() => {
    const today = new TZDate(startOfDay(new Date()));
    return format(today, 'd MMM');
  });

  return (
    <div className="flex flex-row justify-between cursor-pointer">
      {dates.map((dateItem, index) => {
        const isActive = format(dateItem.fullDate, 'd MMM') === activeDate;

        return (
          <button
            onClick={() => {
              setActiveDate(format(dateItem.fullDate, 'd MMM'));
              onChange(dateItem.fullDate);
            }}
            key={index}
            className={cn(
              'flex-1 flex flex-col items-center justify-center px-[2px] py-[4px] h-[40px] first:rounded-l-lg last:rounded-r-lg',
              isActive
                ? 'bg-light-blue text-black font-semibold'
                : 'bg-light-gray text-white hover:bg-light-blue-o15'
            )}
          >
            <p className="text-sm">{dateItem.label}</p>
            <p className="text-[10px]">{dateItem.date}</p>
          </button>
        );
      })}
    </div>
  );
};

export default Calendar;
