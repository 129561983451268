/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Game studio API
 * Game studio API documentation OAS.
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ItemDotaSports,
  RssFeed,
  RssItem
} from '../../backoffice-schemas'
import getApiRssHltvMutator from '../../../lib/customInstance';
import getApiRssDotaBuffMutator from '../../../lib/customInstance';
import getApiRssFeedMutator from '../../../lib/customInstance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getApiRssHltv = (
    
 options?: SecondParameter<typeof getApiRssHltvMutator>,signal?: AbortSignal
) => {
      
      
      return getApiRssHltvMutator<RssItem[]>(
      {url: `https://api.dev.gocore.gg/api/Rss/hltv`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiRssHltvQueryKey = () => {
    return [`https://api.dev.gocore.gg/api/Rss/hltv`] as const;
    }

    
export const getGetApiRssHltvQueryOptions = <TData = Awaited<ReturnType<typeof getApiRssHltv>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssHltv>>, TError, TData>>, request?: SecondParameter<typeof getApiRssHltvMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiRssHltvQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRssHltv>>> = ({ signal }) => getApiRssHltv(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiRssHltv>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiRssHltvQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRssHltv>>>
export type GetApiRssHltvQueryError = unknown


export function useGetApiRssHltv<TData = Awaited<ReturnType<typeof getApiRssHltv>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssHltv>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiRssHltv>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiRssHltvMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiRssHltv<TData = Awaited<ReturnType<typeof getApiRssHltv>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssHltv>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiRssHltv>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiRssHltvMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiRssHltv<TData = Awaited<ReturnType<typeof getApiRssHltv>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssHltv>>, TError, TData>>, request?: SecondParameter<typeof getApiRssHltvMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiRssHltv<TData = Awaited<ReturnType<typeof getApiRssHltv>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssHltv>>, TError, TData>>, request?: SecondParameter<typeof getApiRssHltvMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiRssHltvQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiRssDotaBuff = (
    
 options?: SecondParameter<typeof getApiRssDotaBuffMutator>,signal?: AbortSignal
) => {
      
      
      return getApiRssDotaBuffMutator<ItemDotaSports[]>(
      {url: `https://api.dev.gocore.gg/api/Rss/dota-buff`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiRssDotaBuffQueryKey = () => {
    return [`https://api.dev.gocore.gg/api/Rss/dota-buff`] as const;
    }

    
export const getGetApiRssDotaBuffQueryOptions = <TData = Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError, TData>>, request?: SecondParameter<typeof getApiRssDotaBuffMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiRssDotaBuffQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRssDotaBuff>>> = ({ signal }) => getApiRssDotaBuff(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiRssDotaBuffQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRssDotaBuff>>>
export type GetApiRssDotaBuffQueryError = unknown


export function useGetApiRssDotaBuff<TData = Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiRssDotaBuff>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiRssDotaBuffMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiRssDotaBuff<TData = Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiRssDotaBuff>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiRssDotaBuffMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiRssDotaBuff<TData = Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError, TData>>, request?: SecondParameter<typeof getApiRssDotaBuffMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiRssDotaBuff<TData = Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssDotaBuff>>, TError, TData>>, request?: SecondParameter<typeof getApiRssDotaBuffMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiRssDotaBuffQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiRssFeed = (
    
 options?: SecondParameter<typeof getApiRssFeedMutator>,signal?: AbortSignal
) => {
      
      
      return getApiRssFeedMutator<RssFeed[]>(
      {url: `https://api.dev.gocore.gg/api/Rss/feed`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiRssFeedQueryKey = () => {
    return [`https://api.dev.gocore.gg/api/Rss/feed`] as const;
    }

    
export const getGetApiRssFeedQueryOptions = <TData = Awaited<ReturnType<typeof getApiRssFeed>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssFeed>>, TError, TData>>, request?: SecondParameter<typeof getApiRssFeedMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiRssFeedQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRssFeed>>> = ({ signal }) => getApiRssFeed(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiRssFeed>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiRssFeedQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRssFeed>>>
export type GetApiRssFeedQueryError = unknown


export function useGetApiRssFeed<TData = Awaited<ReturnType<typeof getApiRssFeed>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssFeed>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiRssFeed>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiRssFeedMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiRssFeed<TData = Awaited<ReturnType<typeof getApiRssFeed>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssFeed>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiRssFeed>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiRssFeedMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiRssFeed<TData = Awaited<ReturnType<typeof getApiRssFeed>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssFeed>>, TError, TData>>, request?: SecondParameter<typeof getApiRssFeedMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiRssFeed<TData = Awaited<ReturnType<typeof getApiRssFeed>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRssFeed>>, TError, TData>>, request?: SecondParameter<typeof getApiRssFeedMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiRssFeedQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



