import { cn } from '@/utils/cn';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { cva, type VariantProps } from 'class-variance-authority';
import {
  forwardRef,
  useState,
  type InputHTMLAttributes,
  type ReactNode,
} from 'react';

// 1) Omit 'size' from the native input attributes
type NativeInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

const containerVariants = cva(
  'relative flex items-center bg-dark-gray px-4 rounded-md gap-4 focus-within:ring-2 focus-within:ring-blue-gray',
  {
    variants: {
      error: {
        true: 'ring-2 ring-error',
        false: '',
      },
    },
    defaultVariants: {
      error: false,
    },
  }
);

const inputVariants = cva(
  'w-full bg-transparent text-gray-500 focus:outline-none text-sm focus:ring-0',
  {
    variants: {
      // 2) Keep your own "size" variant for controlling the height
      size: {
        sm: 'h-8',
        md: 'h-9',
        lg: 'h-12',
      },
    },
    defaultVariants: {
      size: 'lg',
    },
  }
);

// 3) Extend our custom NativeInputProps instead of the unmodified InputHTMLAttributes
export interface InputProps
  extends NativeInputProps,
    VariantProps<typeof inputVariants> {
  label?: string;
  name: string;
  leftIcon?: ReactNode;
  error?: string;
  wrapperClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      name,
      leftIcon,
      error,
      type = 'text',
      className,
      wrapperClassName,
      size,
      ...props
    },
    ref
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const isPasswordField = type === 'password';
    const inputType = isPasswordField && passwordVisible ? 'text' : type;

    const togglePasswordVisibility = () => {
      setPasswordVisible((prev) => !prev);
    };

    return (
      <div className={cn('w-full', wrapperClassName)}>
        {label && (
          <label htmlFor={name} className="block mb-1 text-sm text-white">
            {label}
          </label>
        )}

        <div
          className={cn(
            containerVariants({
              error: Boolean(error),
            }),
            className
          )}
        >
          {leftIcon && <>{leftIcon}</>}

          <input
            id={name}
            name={name}
            ref={ref}
            type={inputType}
            className={cn(
              inputVariants({
                size,
              }),
              className
            )}
            {...props}
          />

          {isPasswordField && (
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="text-gray-500 hover:text-gray-300 focus:outline-none"
            >
              {passwordVisible ? (
                <EyeSlashIcon className="w-5 h-5" />
              ) : (
                <EyeIcon className="w-5 h-5" />
              )}
            </button>
          )}
        </div>

        {error && <p className="mt-1 text-sm text-red">{error}</p>}
      </div>
    );
  }
);

Input.displayName = 'Input';
