// components/MobileNavigation.tsx

import { Button } from '@/components/button';
import { cn } from '@/utils/cn';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import Close from '@public/icons/close.svg';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Fragment, type FC, type ImgHTMLAttributes } from 'react';
import { AuthenticationButtons } from '../AuthenticationButtons';
import {
  generateHrefToNavigateTo,
  isPathActive,
  NAVIGATION_DATA,
  type NavItem,
} from './navigation.utils';

interface SocialItem {
  name: string;
  href: string;
  icon: FC<ImgHTMLAttributes<HTMLImageElement>>;
}

interface MobileNavigationProps {
  open: boolean;
  toggle: () => void;
  /**
   * The same array you might pass to the desktop menu, e.g.:
   *
   *     [
   *       { id: 'home', name: 'Home', href: '/', matchGamePrefix: true },
   *       { id: 'news', name: 'News', href: '/news', ... },
   *       ...
   *     ]
   */
  social: SocialItem[];
  className?: string;
}

// Sub-components
const CloseButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className="flex justify-end h-16 items-center">
    <button type="button" className="-m-2.5 p-2.5" onClick={onClick}>
      <span className="sr-only">Close menu</span>
      <Close />
    </button>
  </div>
);

/**
 * A single top-level or sub-level link, reusing
 * `generateHrefToNavigateTo` for prefix logic.
 */
const NavigationLink: FC<{
  item: NavItem;
  currentPath: string;
  isActive: boolean;
  onClick: () => void;
}> = ({ item, currentPath, isActive, onClick }) => {
  const href = generateHrefToNavigateTo(
    currentPath,
    item.href,
    item.matchGamePrefix
  );

  return (
    <Button
      as="link"
      variant={isActive ? 'secondary' : 'ghost'}
      className="text-left justify-start"
      size="lg"
      href={href}
      onClick={onClick}
    >
      {item.name}
    </Button>
  );
};

/**
 * Renders a "parent" with sub-links. For example,
 * "Betting HUB" => [ "Bookmakers", "Bonuses" ].
 */
const SubNavigation: FC<{
  parent: NavItem;
  pathname: string;
  onLinkClick: () => void;
}> = ({ parent, pathname, onLinkClick }) => (
  <div className="flex flex-col">
    <p className="leading-5 px-3 text-base font-medium text-white rounded-lg py-[0.906rem]">
      {parent.name}
    </p>
    <div className="mt-2 space-y-2 ml-5">
      {parent.subLinks?.map((sub) => {
        const subActive = isPathActive(pathname, sub);
        return (
          <NavigationLink
            key={sub.id}
            item={sub}
            currentPath={pathname}
            isActive={subActive}
            onClick={onLinkClick}
          />
        );
      })}
    </div>
  </div>
);

/** Renders the row of social icons at the bottom of the menu. */
const SocialLinks: FC<{ social: SocialItem[] }> = ({ social }) => (
  <div className="flex flex-row gap-x-4 px-3">
    {social.map((item, index) => (
      <Link
        target="_blank"
        key={index}
        href={item.href}
        className="text-gray-400 hover:text-gray-500"
      >
        <span className="sr-only">{item.name}</span>
        <item.icon className="h-6 w-6" aria-hidden="true" />
      </Link>
    ))}
  </div>
);

/**
 * Loops through main `navigation` and either:
 * - Renders a SubNavigation if `subLinks` are present
 * - Renders a single link otherwise
 */
const NavigationContent: FC<{
  pathname: string;
  onLinkClick: () => void;
}> = ({ pathname, onLinkClick }) => (
  <div className="mt-5 space-y-2">
    {NAVIGATION_DATA.map((item) => {
      const active = isPathActive(pathname, item);

      if (item.subLinks && item.subLinks.length > 0) {
        return (
          <SubNavigation
            key={item.id}
            parent={item}
            pathname={pathname}
            onLinkClick={onLinkClick}
          />
        );
      }

      return (
        <NavigationLink
          key={item.id}
          item={item}
          currentPath={pathname}
          isActive={active}
          onClick={onLinkClick}
        />
      );
    })}
  </div>
);

// ---- The main MobileNavigation component:
export const MobileNavigation: FC<MobileNavigationProps> = ({
  open,
  toggle,

  social,
  className,
}) => {
  const pathname = usePathname();

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="lg:hidden" onClose={toggle}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-x-[-100%]"
          enterTo="opacity-100 translate-x-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-[-100%]"
        >
          <DialogPanel
            className={cn(
              'fixed inset-y-0 left-0 z-40 w-full overflow-y-auto bg-medium-gray text-white p-4 flex flex-col',
              className
            )}
          >
            <CloseButton onClick={toggle} />
            <div className="flex flex-col justify-between gap-8">
              <NavigationContent pathname={pathname} onLinkClick={toggle} />
              <AuthenticationButtons />
              <div className="flex flex-col gap-y-6">
                <SocialLinks social={social} />
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};
