import LogoSmall from '@public/icons/gocore-logo-small.svg';
import Logo from '@public/icons/gocore-logo.svg';

interface IGoCoreLogo {
  className?: string;
}

export const GoCoreLogo = ({ className }: IGoCoreLogo) => {
  return (
    <>
      <div className="hidden md:block">
        <Logo className={className} />
      </div>
      <div className="md:hidden">
        <LogoSmall className={className} />
      </div>
    </>
  );
};
