import { Button } from '@/components/button';
import { cn } from '@/utils/cn';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import {
  generateHrefToNavigateTo,
  isPathActive,
  NAVIGATION_DATA,
} from './navigation.utils';

export type DesktopNavigationProps = {
  className?: string;
};

export const DesktopNavigation = ({ className }: DesktopNavigationProps) => {
  const pathname = usePathname();

  return (
    <nav className={cn('hidden lg:flex flex-1', className)}>
      {NAVIGATION_DATA.map((item) => {
        const hasSubLinks = item.subLinks && item.subLinks.length > 0;
        const isActive = isPathActive(pathname, item);

        // Generate the href considering the game prefix
        const generatedHref = generateHrefToNavigateTo(
          pathname,
          item.href,
          item.matchGamePrefix || false
        );

        if (hasSubLinks) {
          return (
            <Popover className="relative" key={item.id}>
              {({ open, close }) => (
                <>
                  <PopoverButton
                    as={Button}
                    variant={
                      open ? 'secondary' : isActive ? 'primary' : 'ghost'
                    }
                    className="flex items-center space-x-1"
                  >
                    <span>{item.name}</span>
                    <ChevronDownIcon
                      className={cn(
                        'w-4 h-4 transition-transform',
                        open ? 'transform rotate-180' : ''
                      )}
                    />
                  </PopoverButton>
                  <PopoverPanel className="absolute z-10 mt-2 w-56">
                    <div className="rounded-lg bg-dark-gray text-sm shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-4">
                        {item.subLinks?.map((subItem) => {
                          const isSubActive = isPathActive(pathname, subItem);
                          const subItemHref = generateHrefToNavigateTo(
                            pathname,
                            subItem.href,
                            subItem.matchGamePrefix || false
                          );

                          return (
                            <Link
                              key={subItem.id}
                              href={subItemHref}
                              onClick={() => close()}
                              className={cn(
                                'flex items-center gap-2 rounded-lg py-2 px-3 hover:bg-light-blue-o10',
                                isSubActive && 'text-light-blue'
                              )}
                            >
                              {subItem.icon && (
                                <span className="flex-shrink-0">
                                  {subItem.icon}
                                </span>
                              )}
                              <span className="font-medium text-white">
                                {subItem.name}
                              </span>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </PopoverPanel>
                </>
              )}
            </Popover>
          );
        }

        return (
          <Button
            as="link"
            key={item.id}
            href={generatedHref}
            variant={isActive ? 'primary' : 'ghost'}
            className="mx-2"
          >
            {item.name}
          </Button>
        );
      })}
    </nav>
  );
};
