'use client';

import { Game } from '@/enums';
import { useGetGameFromRouteParams } from '@/hooks';
import CSIcon from '@public/icons/games/cs.svg';
import DotaIcon from '@public/icons/games/dota.svg';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { Button } from './button';

function buildGamePath(
  pathname: string,
  currentGame: Game | undefined,
  targetGame: Game | undefined
) {
  const segments = pathname.replace(/^\/+/, '').split('/');
  const firstSegment = segments[0] || '';
  const knownCurrentPrefix = currentGame ? currentGame : null;
  const isFirstSegmentGame = knownCurrentPrefix === firstSegment;

  if (segments.length >= 3 && pathname.includes('pickems') && !targetGame) {
    return '/pickems';
  }

  if (!targetGame) {
    // "All"
    if (isFirstSegmentGame) {
      segments.shift();
    }
  } else {
    // Switching to a specific game
    const newPrefix = targetGame;
    if (isFirstSegmentGame) {
      segments[0] = newPrefix;
    } else {
      segments.unshift(newPrefix);
    }
  }

  const newPath = '/' + segments.join('/');
  return newPath === '/' ? '/' : newPath;
}

export const GameSelectionNavigation = () => {
  const { game } = useGetGameFromRouteParams(); // e.g. Game.CSGO, or undefined for All
  const pathname = usePathname() || '/';

  const isAllActive = !Boolean(game);
  const isDotaActive = game === Game.DOTA;
  const isCSActive = game === Game.CSGO;

  const allNavigationLink = useMemo(
    () => buildGamePath(pathname, game, undefined), // => "All"
    [pathname, game]
  );

  const dotaNavigationLink = useMemo(
    () => buildGamePath(pathname, game, Game.DOTA),
    [pathname, game]
  );

  const csgoNavigationLink = useMemo(
    () => buildGamePath(pathname, game, Game.CSGO),
    [pathname, game]
  );

  return (
    <div className="flex flex-row bg-medium-gray top-16 z-30 sticky w-full">
      <div className="container mx-auto max-w-screen-xl flex w-full items-center space-x-4 px-4 py-[0.5rem]">
        {/* All */}
        <Button
          variant={isAllActive ? 'default' : 'ghost'}
          size="sm"
          as="link"
          href={allNavigationLink}
        >
          All
        </Button>

        <>
          {/* Dota */}
          <Button
            as="link"
            href={dotaNavigationLink}
            variant={isDotaActive ? 'default' : 'ghost'}
            size="sm"
          >
            <DotaIcon className="w-4 text-white" />
            Dota 2
          </Button>

          {/* CS */}
          <Button
            as="link"
            href={csgoNavigationLink}
            variant={isCSActive ? 'default' : 'ghost'}
            size="sm"
          >
            <CSIcon className="w-4 text-white" />
            CS 2
          </Button>
        </>
      </div>
    </div>
  );
};
