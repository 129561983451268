'use client';

import { LockClosedIcon, UserIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Bounce, toast } from 'react-toastify';
import { z } from 'zod';

import { signInAction } from '@/actions/sign-in.action';
import { Button } from '@/components/button';
import { Input } from '@/components/Input/Input';
import { useAuthDialog } from '@/context/AuthDialogProvider';

// Zod validation schema
const loginSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Invalid email format'),
  password: z.string().min(1, 'Password is required'),
});

type SignInFormData = z.infer<typeof loginSchema>;

export const SignInForm = () => {
  const { toggleIsOpen } = useAuthDialog();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormData>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data: SignInFormData) => {
    try {
      await signInAction(data);
      toast.info('Login successful', {
        position: 'top-right',
        autoClose: Infinity,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce,
      });
      toggleIsOpen();
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: 'Email or password incorrect',
      });
      setError('password', {
        type: 'manual',
        message: 'Email or password incorrect',
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-col gap-2">
        <div className="self-center flex flex-col gap-5 w-full max-w-[480px] mb-8">
          <h2 className="text-2xl font-semibold">
            Welcome back, please sign in
          </h2>
        </div>

        <div className="self-center flex flex-col gap-5 w-full max-w-[480px]">
          <div className="flex flex-col gap-2 w-full">
            <Input
              {...register('email')}
              leftIcon={<UserIcon className="size-5" />}
              type="email"
              label="Email"
              placeholder="eg. john@example.com"
              error={errors.email?.message}
            />

            <Input
              {...register('password')}
              type="password"
              leftIcon={<LockClosedIcon className="size-5" />}
              placeholder="your password"
              label="Password"
              error={errors.password?.message}
            />
          </div>

          <Button
            type="submit"
            variant="primary"
            size="lg"
            disabled={isSubmitting}
            onClick={(e) => e.stopPropagation()}
          >
            Sign In
          </Button>
        </div>
      </div>
    </form>
  );
};
