import { env } from '@/config/env';
import { setCookie } from 'cookies-next';
import xior from 'xior';

interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

export async function signInAction({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<LoginResponse> {
  const { data } = await xior.post<LoginResponse>(
    `${env.NEXT_PUBLIC_API_URL}/connect/token`,
    {
      client_id: 'jwt.web-api',
      client_secret: 'secret',
      grant_type: 'credentials',
      email,
      credentials: password,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

  const { access_token, refresh_token } = data || {};

  if (!access_token || !refresh_token) {
    throw new Error('Login failed: Invalid response');
  }

  await setCookie('access_token', access_token, {
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
    path: '/',
  });

  await setCookie('refresh_token', refresh_token, {
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
    path: '/',
  });

  return { access_token, refresh_token };
}
