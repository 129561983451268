'use client';

import type { MatchDetailDto } from '@/api/backoffice-schemas';
import { useGetApiMatchesMatchesCalendar } from '@/api/backoffice/matches/matches';
import { Button } from '@/components/button';
import Calendar from '@/components/calendar';
import EventCard from '@/components/cards/eventCard';
import SkeletonEvent from '@/components/skeletons/event';
import { useGetGameFromRouteParams } from '@/hooks';
import { mapGameFilterToVideoGameName } from '@/lib/utils';
import { cn } from '@/utils/cn';
import { TZDate } from '@date-fns/tz';
import { startOfDay } from 'date-fns';
import { FC, useMemo, useState } from 'react';

interface IEventsCalendar {
  type: 'finished' | 'not_started';
  className?: string;
}

export const EventsCalendar: FC<IEventsCalendar> = ({ type, className }) => {
  const { game } = useGetGameFromRouteParams();
  const [date, setDate] = useState<TZDate>(
    () => new TZDate(startOfDay(new Date()))
  );

  // Memoize game parameter
  const gameParam = useMemo(() => mapGameFilterToVideoGameName(game), [game]);

  // Memoize formatted date
  const formattedDate = useMemo(() => date.toISOString().split('T')[0], [date]);

  const { data, isLoading } = useGetApiMatchesMatchesCalendar(
    {
      Status: type === 'finished' ? 'finished' : 'not_started',
      BeginAt: formattedDate,
      Game: gameParam,
      PageSize: 5,
    },
    {
      query: {
        queryKey: ['matches-calendar', type, formattedDate, game],
        staleTime: 0,
      },
    }
  );

  return (
    <div
      className={cn(
        'flex flex-col w-fill bg-medium-gray gap-5 p-4 rounded-lg',
        className
      )}
    >
      <div className="flex flex-row justify-start items-center">
        <p className="font-semibold text-basetext-gray">
          {type === 'finished' ? 'Match Results' : 'Upcoming Events'}
        </p>
      </div>
      <div className="flex flex-col gap-[0.875rem] md:gap-[0.75rem]">
        <Calendar
          onChange={(newDate: TZDate) => {
            setDate(new TZDate(startOfDay(newDate)));
          }}
          type={type === 'finished' ? 'past' : 'future'}
        />
        <div className="flex flex-col gap-[0.625rem]">
          {isLoading ? (
            Array.from({ length: 5 }, (_, index) => (
              <SkeletonEvent key={index} />
            ))
          ) : data?.data.items && data.data.items.length > 0 ? (
            data.data.items.map((item: MatchDetailDto, index: number) => (
              <EventCard data={item} key={index} />
            ))
          ) : (
            <div className="flex flex-col flex-1 px-3 py-5 rounded-lg bg-component-bg gap-2">
              <p className="text-gray font-medium text-sm leading-[18px]">
                {type === 'finished'
                  ? 'No matches played yet.'
                  : 'No more matches today.'}
              </p>
              <p className="text-gray font-normal text-[10px] leading-[12px]">
                {type === 'finished'
                  ? 'Check out later today.'
                  : 'Check out tomorrows schedule.'}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="flex md:justify-end">
        <Button
          as="link"
          variant="outline"
          size="lg"
          href="/matches"
          className="max-w-52 w-full"
        >
          More Matches
        </Button>
      </div>
    </div>
  );
};
