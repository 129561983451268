import TeamPlaceholderSmall from '@public/icons/teams/team-placeholder.svg';
import Image from 'next/image';
import { FC } from 'react';

interface ITeamLogo {
  url: string | null;
}

const LeagueLogo: FC<ITeamLogo> = ({ url }) => {
  return (
    <>
      {url ? (
        <Image
          alt="league logo"
          src={`${url}`}
          width={32}
          height={32}
          className="block rounded-lg"
        />
      ) : (
        <TeamPlaceholderSmall />
      )}
    </>
  );
};

export default LeagueLogo;
