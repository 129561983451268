'use client';
import { TipDetailDto } from '@/api/backoffice-schemas';
import LeagueLogo from '@/components/leagueLogo';
import TeamLogo from '@/components/teamLogo';
import { cn } from '@/utils/cn';
import Check from '@public/icons/check.svg';
import Dash from '@public/icons/dash.svg';
import CsIcon from '@public/icons/games/cs.svg';
import DotaIcon from '@public/icons/games/dota.svg';
import LolIcon from '@public/icons/games/lol.svg';
import Avatar from '@public/icons/meta/avatar.svg';
import Clock from '@public/icons/meta/clock.svg';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

interface TipCardProps {
  data: TipDetailDto;
  className?: string;
}

const TipCard: FC<TipCardProps> = ({ data, className = 'bg-medium-gray' }) => {
  const teamA = data.match?.teamMatches?.[0]?.team;
  const teamB = data.match?.teamMatches?.[1]?.team;
  const league = data.match?.league;
  const game = data.match?.videoGame;
  const isFinished = data.match?.status === 'Finished';

  const getIcon = () => {
    switch (game?.slug?.toLowerCase()) {
      case 'lol':
        return <LolIcon className="text-cyan" />;
      case 'cs':
      case 'cs2':
        return <CsIcon className="text-orange" />;
      case 'dota':
        return <DotaIcon className="text-red" />;
      default:
        return null;
    }
  };

  return (
    <Link
      href={`/tips/${data.id}`}
      className={cn(
        'flex flex-col md:flex-row gap-2 md:gap-5 p-3 md:p-4 rounded-lg hover:bg-light-blue-o15',
        className
      )}
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-row items-center justify-between md:justify-center flex-1 gap-2">
          <div className="flex flex-row gap-2 items-center">
            {getIcon()}
            {league?.imageUrl && <LeagueLogo url={league.imageUrl} />}
          </div>
          {isFinished ? (
            <p className="text-xs leading-[14px] font-normal text-gray text-center">
              Match
              <br />
              over
            </p>
          ) : (
            <div className="text-xs leading-[14px] font-normal text-gray">
              <div>
                {data.match?.beginAt
                  ? moment(data.match.beginAt).format('DD.MM YYYY')
                  : 'TBD'}
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-1 gap-4 flex-row justify-between items-center">
          <div className="flex flex-col justify-center flex-1 items-center gap-2">
            {teamA?.imageUrl && (
              <TeamLogo
                url={teamA.imageUrl}
                className="w-12 h-12 object-contain"
              />
            )}
            <p className="font-medium text-sm leading-[17px]">
              {teamA?.name || 'TBD'}
            </p>
          </div>

          <div className="rounded-full bg-dark-gray flex items-center justify-center w-[25px] h-[25px]">
            <p className="font-black leading-[11px] text-[9px] text-white">
              VS
            </p>
          </div>

          <div className="flex flex-col justify-center flex-1 items-center gap-2">
            {teamB?.imageUrl && (
              <TeamLogo
                url={teamB.imageUrl}
                className="w-12 h-12 object-contain"
              />
            )}
            <p className="font-medium text-sm leading-[17px]">
              {teamB?.name || 'TBD'}
            </p>
          </div>
        </div>
      </div>

      <div
        className={cn(
          'flex flex-col md:w-[326px] justify-between gap-1 md:p-3 rounded-lg',
          isFinished
            ? data.isCorrect
              ? 'bg-green-o10'
              : 'bg-red-o15'
            : 'bg-component-bg'
        )}
      >
        <div className="flex flex-row gap-2">
          {isFinished &&
            (data.isCorrect ? (
              <Check className="text-green" />
            ) : (
              <Dash className="text-red" />
            ))}
          <p className="font-semibold text-sm">{data.title}</p>
        </div>

        <p className="font-normal text-sm leading-4 text-gray mb-2 line-clamp-3">
          {data.description}
        </p>

        {/* Mobile meta info */}
        <div className="md:hidden flex flex-row gap-1 md:gap-2 items-center py-2 justify-start">
          {data.tipster?.profilePhoto ? (
            <Image
              src={data.tipster.profilePhoto}
              alt="Tipster"
              className="rounded-full object-cover"
              width={24}
              height={24}
            />
          ) : (
            <Avatar className="text-blue-gray w-6 h-6" />
          )}
          <p className="text-xs leading-[18px] font-normal">
            {data.tipster?.userName || 'Anonymous'}
          </p>
          <Clock className="text-blue-gray" />
          <p className="text-xs leading-[18px] font-normal">
            {data.match?.beginAt
              ? moment(data.match.beginAt).format('DD.MM.YYYY')
              : 'TBD'}
          </p>
        </div>

        {/* Desktop meta info */}
        {!isFinished && (
          <div className="hidden md:flex flex-col gap-1 md:gap-2 items-start">
            <div className="flex flex-row gap-1">
              {data.tipster?.profilePhoto ? (
                <Image
                  src={data.tipster.profilePhoto}
                  alt="Tipster"
                  className="rounded-full object-cover"
                  width={24}
                  height={24}
                />
              ) : (
                <Avatar className="text-blue-gray w-6 h-6" />
              )}
              <p className="text-xs leading-[18px] font-normal">
                {data.tipster?.userName || 'Anonymous'}
              </p>
              <Clock className="text-blue-gray" />
              <p className="text-xs leading-[18px] font-normal">
                {data.match?.beginAt
                  ? moment(data.match.beginAt).format('DD.MM.YYYY')
                  : 'TBD'}
              </p>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default TipCard;
