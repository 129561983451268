/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Game studio API
 * Game studio API documentation OAS.
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetApiMatchesMatchParams,
  GetApiMatchesMatchesCalendarParams,
  GetApiMatchesMatchesParams,
  MatchDetailDto,
  MatchDetailDtoPaginatedList
} from '../../backoffice-schemas'
import getApiMatchesMatchMutator from '../../../lib/customInstance';
import getApiMatchesMatchesMutator from '../../../lib/customInstance';
import getApiMatchesMatchesCalendarMutator from '../../../lib/customInstance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getApiMatchesMatch = (
    params: GetApiMatchesMatchParams,
 options?: SecondParameter<typeof getApiMatchesMatchMutator>,signal?: AbortSignal
) => {
      
      
      return getApiMatchesMatchMutator<MatchDetailDto>(
      {url: `https://api.dev.gocore.gg/api/Matches/match`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiMatchesMatchQueryKey = (params: GetApiMatchesMatchParams,) => {
    return [`https://api.dev.gocore.gg/api/Matches/match`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiMatchesMatchQueryOptions = <TData = Awaited<ReturnType<typeof getApiMatchesMatch>>, TError = unknown>(params: GetApiMatchesMatchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatch>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiMatchesMatchQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMatchesMatch>>> = ({ signal }) => getApiMatchesMatch(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatch>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiMatchesMatchQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMatchesMatch>>>
export type GetApiMatchesMatchQueryError = unknown


export function useGetApiMatchesMatch<TData = Awaited<ReturnType<typeof getApiMatchesMatch>>, TError = unknown>(
 params: GetApiMatchesMatchParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatch>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiMatchesMatch>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiMatchesMatchMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiMatchesMatch<TData = Awaited<ReturnType<typeof getApiMatchesMatch>>, TError = unknown>(
 params: GetApiMatchesMatchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatch>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiMatchesMatch>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiMatchesMatchMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiMatchesMatch<TData = Awaited<ReturnType<typeof getApiMatchesMatch>>, TError = unknown>(
 params: GetApiMatchesMatchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatch>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiMatchesMatch<TData = Awaited<ReturnType<typeof getApiMatchesMatch>>, TError = unknown>(
 params: GetApiMatchesMatchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatch>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiMatchesMatchQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiMatchesMatches = (
    params?: GetApiMatchesMatchesParams,
 options?: SecondParameter<typeof getApiMatchesMatchesMutator>,signal?: AbortSignal
) => {
      
      
      return getApiMatchesMatchesMutator<MatchDetailDtoPaginatedList>(
      {url: `https://api.dev.gocore.gg/api/Matches/matches`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiMatchesMatchesQueryKey = (params?: GetApiMatchesMatchesParams,) => {
    return [`https://api.dev.gocore.gg/api/Matches/matches`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiMatchesMatchesQueryOptions = <TData = Awaited<ReturnType<typeof getApiMatchesMatches>>, TError = unknown>(params?: GetApiMatchesMatchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatches>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchesMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiMatchesMatchesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMatchesMatches>>> = ({ signal }) => getApiMatchesMatches(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatches>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiMatchesMatchesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMatchesMatches>>>
export type GetApiMatchesMatchesQueryError = unknown


export function useGetApiMatchesMatches<TData = Awaited<ReturnType<typeof getApiMatchesMatches>>, TError = unknown>(
 params: undefined |  GetApiMatchesMatchesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatches>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiMatchesMatches>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiMatchesMatchesMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiMatchesMatches<TData = Awaited<ReturnType<typeof getApiMatchesMatches>>, TError = unknown>(
 params?: GetApiMatchesMatchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatches>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiMatchesMatches>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiMatchesMatchesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiMatchesMatches<TData = Awaited<ReturnType<typeof getApiMatchesMatches>>, TError = unknown>(
 params?: GetApiMatchesMatchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatches>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiMatchesMatches<TData = Awaited<ReturnType<typeof getApiMatchesMatches>>, TError = unknown>(
 params?: GetApiMatchesMatchesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatches>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiMatchesMatchesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiMatchesMatchesCalendar = (
    params?: GetApiMatchesMatchesCalendarParams,
 options?: SecondParameter<typeof getApiMatchesMatchesCalendarMutator>,signal?: AbortSignal
) => {
      
      
      return getApiMatchesMatchesCalendarMutator<MatchDetailDtoPaginatedList>(
      {url: `https://api.dev.gocore.gg/api/Matches/matches-calendar`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiMatchesMatchesCalendarQueryKey = (params?: GetApiMatchesMatchesCalendarParams,) => {
    return [`https://api.dev.gocore.gg/api/Matches/matches-calendar`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiMatchesMatchesCalendarQueryOptions = <TData = Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError = unknown>(params?: GetApiMatchesMatchesCalendarParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchesCalendarMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiMatchesMatchesCalendarQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>> = ({ signal }) => getApiMatchesMatchesCalendar(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiMatchesMatchesCalendarQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>>
export type GetApiMatchesMatchesCalendarQueryError = unknown


export function useGetApiMatchesMatchesCalendar<TData = Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError = unknown>(
 params: undefined |  GetApiMatchesMatchesCalendarParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiMatchesMatchesCalendarMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiMatchesMatchesCalendar<TData = Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError = unknown>(
 params?: GetApiMatchesMatchesCalendarParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiMatchesMatchesCalendarMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiMatchesMatchesCalendar<TData = Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError = unknown>(
 params?: GetApiMatchesMatchesCalendarParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchesCalendarMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiMatchesMatchesCalendar<TData = Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError = unknown>(
 params?: GetApiMatchesMatchesCalendarParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiMatchesMatchesCalendar>>, TError, TData>>, request?: SecondParameter<typeof getApiMatchesMatchesCalendarMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiMatchesMatchesCalendarQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



