/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Game studio API
 * Game studio API documentation OAS.
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreatePlayerCommand,
  ModifyPlayerCommand,
  PlayerDetailDto
} from '../../backoffice-schemas'
import postApiPlayersMutator from '../../../lib/customInstance';
import putApiPlayersMutator from '../../../lib/customInstance';
import getApiPlayersMutator from '../../../lib/customInstance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const postApiPlayers = (
    createPlayerCommand: CreatePlayerCommand,
 options?: SecondParameter<typeof postApiPlayersMutator>,signal?: AbortSignal
) => {
      
      
      return postApiPlayersMutator<PlayerDetailDto>(
      {url: `https://api.dev.gocore.gg/api/Players`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createPlayerCommand, signal
    },
      options);
    }
  


export const getPostApiPlayersMutationOptions = <TData = Awaited<ReturnType<typeof postApiPlayers>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreatePlayerCommand}, TContext>, request?: SecondParameter<typeof postApiPlayersMutator>}
) => {
const mutationKey = ['postApiPlayers'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiPlayers>>, {data: CreatePlayerCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiPlayers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: CreatePlayerCommand}, TContext>}

    export type PostApiPlayersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiPlayers>>>
    export type PostApiPlayersMutationBody = CreatePlayerCommand
    export type PostApiPlayersMutationError = unknown

    export const usePostApiPlayers = <TData = Awaited<ReturnType<typeof postApiPlayers>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreatePlayerCommand}, TContext>, request?: SecondParameter<typeof postApiPlayersMutator>}
): UseMutationResult<
        TData,
        TError,
        {data: CreatePlayerCommand},
        TContext
      > => {

      const mutationOptions = getPostApiPlayersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putApiPlayers = (
    modifyPlayerCommand: ModifyPlayerCommand,
 options?: SecondParameter<typeof putApiPlayersMutator>,) => {
      
      
      return putApiPlayersMutator<PlayerDetailDto>(
      {url: `https://api.dev.gocore.gg/api/Players`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: modifyPlayerCommand
    },
      options);
    }
  


export const getPutApiPlayersMutationOptions = <TData = Awaited<ReturnType<typeof putApiPlayers>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ModifyPlayerCommand}, TContext>, request?: SecondParameter<typeof putApiPlayersMutator>}
) => {
const mutationKey = ['putApiPlayers'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiPlayers>>, {data: ModifyPlayerCommand}> = (props) => {
          const {data} = props ?? {};

          return  putApiPlayers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: ModifyPlayerCommand}, TContext>}

    export type PutApiPlayersMutationResult = NonNullable<Awaited<ReturnType<typeof putApiPlayers>>>
    export type PutApiPlayersMutationBody = ModifyPlayerCommand
    export type PutApiPlayersMutationError = unknown

    export const usePutApiPlayers = <TData = Awaited<ReturnType<typeof putApiPlayers>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ModifyPlayerCommand}, TContext>, request?: SecondParameter<typeof putApiPlayersMutator>}
): UseMutationResult<
        TData,
        TError,
        {data: ModifyPlayerCommand},
        TContext
      > => {

      const mutationOptions = getPutApiPlayersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getApiPlayers = (
    
 options?: SecondParameter<typeof getApiPlayersMutator>,signal?: AbortSignal
) => {
      
      
      return getApiPlayersMutator<PlayerDetailDto>(
      {url: `https://api.dev.gocore.gg/api/Players`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiPlayersQueryKey = () => {
    return [`https://api.dev.gocore.gg/api/Players`] as const;
    }

    
export const getGetApiPlayersQueryOptions = <TData = Awaited<ReturnType<typeof getApiPlayers>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiPlayers>>, TError, TData>>, request?: SecondParameter<typeof getApiPlayersMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiPlayersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiPlayers>>> = ({ signal }) => getApiPlayers(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiPlayers>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetApiPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPlayers>>>
export type GetApiPlayersQueryError = unknown


export function useGetApiPlayers<TData = Awaited<ReturnType<typeof getApiPlayers>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiPlayers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiPlayersMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiPlayers<TData = Awaited<ReturnType<typeof getApiPlayers>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiPlayers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiPlayers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof getApiPlayersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetApiPlayers<TData = Awaited<ReturnType<typeof getApiPlayers>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiPlayers>>, TError, TData>>, request?: SecondParameter<typeof getApiPlayersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetApiPlayers<TData = Awaited<ReturnType<typeof getApiPlayers>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiPlayers>>, TError, TData>>, request?: SecondParameter<typeof getApiPlayersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetApiPlayersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



