/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

import { Button } from '@/components/button';
import BookmakerCard from '@/components/cards/bookmakerCard';
import SkeletonArticle from '@/components/skeletons/article';
import useGetBookmakers from '@/hooks/useGetBookmakers';
import { cn } from '@/utils/cn';

interface IFeaturedBookmakers {
  className?: string;
}

export const FeaturedBookmakers = ({ className }: IFeaturedBookmakers) => {
  const { data, isLoading } = useGetBookmakers({ IsFeatured: true });
  return (
    <div
      className={cn(
        'flex flex-col bg-medium-gray gap-4 p-4 rounded-lg',
        className
      )}
    >
      <div className="flex flex-row justify-between items-center">
        <p className="font-semibold text-base">Featured Bookmakers</p>
        <p className="max-md:hidden font-semibold	text-xs	">
          Reviewed by the GOCORE.GG team.
        </p>
      </div>
      <div className="flex flex-col gap-[0.75rem]">
        {!isLoading && data?.data
          ? data.data
              .sort((a: any, b: any) => a.FeaturedPosition - b.FeaturedPosition)
              .slice(0, 5)
              .map((item: any, index: number) => (
                <BookmakerCard key={index} data={item} />
              ))
          : Array.from({ length: 5 }, (_, index) => (
              <SkeletonArticle key={index} />
            ))}
      </div>
      <div className="flex justify-end">
        <Button as="link" variant="outline" size="lg" href="/hub">
          Compare Bookmakers
        </Button>
      </div>
    </div>
  );
};
