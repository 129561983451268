import { cn } from '@/utils/cn';
import TeamPlaceholderMedium from '@public/icons/teams/team-placeholder-medium.svg';
import Image from 'next/image';
import { FC } from 'react';

interface ITeamLogo {
  url: string | null;
  className?: string;
}

const TeamLogo: FC<ITeamLogo> = ({ url, className }) => {
  return (
    <>
      {url ? (
        <Image
          alt={url}
          src={url}
          width={32}
          height={32}
          className={cn('block rounded-lg', className)}
          layout="responsive"
        />
      ) : (
        <TeamPlaceholderMedium />
      )}
    </>
  );
};

export default TeamLogo;
