'use client';

import CloseIcon from '@public/icons/close.svg';
import MenuIcon from '@public/icons/menu.svg';
import InstagramIcon from '@public/icons/socials/instagram.svg';
import TikTokIcon from '@public/icons/socials/tiktok.svg';
import TwitterIcon from '@public/icons/socials/twitter.svg';
import YoutubeIcon from '@public/icons/socials/youtube.svg';
import { useState } from 'react';
import { Button } from '../button';
import { AuthenticationButtons } from './AuthenticationButtons';
import Logo from './logo';
import { DesktopNavigation } from './Navigation/DesktopNavigation';
import { MobileNavigation } from './Navigation/MobileNavigation';

const social = [
  {
    name: 'Twitter',
    href: 'https://x.com',
    icon: TwitterIcon,
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com',
    icon: YoutubeIcon,
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com',
    icon: InstagramIcon,
  },
  {
    name: 'TikTok',
    href: 'https://www.tiktok.com',
    icon: TikTokIcon,
  },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen((prev) => !prev);

  return (
    <header className="w-full text-white inset-x-0 sticky top-0 z-50 flex flex-col bg-transparent">
      {/* Header Top */}
      <div className="flex flex-row w-full bg-dark-gray h-16">
        <div className="container mx-auto max-w-screen-xl px-4">
          <div className="flex items-center justify-between h-full">
            <div className="flex items-center gap-4 sm:gap-6 lg:gap-8">
              <Logo />
              <DesktopNavigation />
            </div>
            <div className="flex items-center gap-2 sm:gap-4">
              <div className="hidden lg:flex">
                <AuthenticationButtons />
              </div>
              <Button
                square
                size="sm"
                type="button"
                className="lg:hidden flex items-center justify-center"
                onClick={toggleMobileMenu}
              >
                {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <MobileNavigation
        open={mobileMenuOpen}
        toggle={toggleMobileMenu}
        social={social}
      />
    </header>
  );
};

export default Header;
