'use client';

import { useGetApiBackOfficeUsersCurrentUserDetail } from '@/api/backoffice/back-office-users/back-office-users';
import { Button } from '@/components/button';
import Loader from '@/components/loader';
import { useAuthDialog } from '@/context/AuthDialogProvider';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export const AuthenticationButtons = () => {
  const { toggleIsOpen } = useAuthDialog();
  const queryClient = useQueryClient();

  const { data, isLoading, error } =
    useGetApiBackOfficeUsersCurrentUserDetail();

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries();
    };
  }, [queryClient]);

  // Error state handling
  if (error) {
    return (
      <div className="items-center gap-4 lg:flex grid grid-cols-2">
        <Button onClick={() => toggleIsOpen('sign-in')} variant="outline">
          Login
        </Button>
        <Button variant="primary" onClick={() => toggleIsOpen('sign-up')}>
          Register
        </Button>
      </div>
    );
  }

  if (data?.data) {
    return (
      <Button as="link" href="/profile" variant="primary">
        Profile
      </Button>
    );
  }

  if (!data && isLoading) {
    return <Loader />;
  }

  return (
    <div className="items-center gap-4 lg:flex grid grid-cols-2">
      <Button onClick={() => toggleIsOpen('sign-in')} variant="outline">
        Login
      </Button>
      <Button variant="primary" onClick={() => toggleIsOpen('sign-up')}>
        Register
      </Button>
    </div>
  );
};
