'use client';

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { createContext, useContext, useState, type ReactNode } from 'react';

import { SignInForm } from '@/components/forms/SignInForm';
import { SignUpForm } from '@/components/forms/SignUpForm';
import { useQueryClient } from '@tanstack/react-query';
import { GoCoreLogo } from '../components/logo';

type AuthDialogContextType = {
  isOpen: boolean;
  toggleIsOpen: (mode?: AuthMode) => void;
};

const SignUpModalContext = createContext<AuthDialogContextType | undefined>(
  undefined
);

type AuthMode = 'sign-up' | 'sign-in';

export const AuthDialogProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState<AuthMode | null>(null);

  const queryClient = useQueryClient();

  const toggleIsOpen = async (authMode?: AuthMode) => {
    if (!isOpen && authMode) {
      setIsOpen(true);
      setMode(authMode);
    } else {
      setIsOpen(false);
      setMode(null);
      await queryClient.invalidateQueries();
    }
  };

  return (
    <SignUpModalContext.Provider value={{ isOpen, toggleIsOpen }}>
      {children}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop className="fixed inset-0 bg-black/80" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel
            transition
            className="bg-light-gray p-6 rounded-lg md:p-12 text-left align-middle shadow-xl transition duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <div className="flex items-center justify-between mb-5">
              <GoCoreLogo />
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="text-white hover:opacity-80 transition-opacity"
              >
                <XMarkIcon className="size-6" />
              </button>
            </div>

            {mode === 'sign-up' ? <SignUpForm /> : <SignInForm />}
          </DialogPanel>
        </div>
      </Dialog>
    </SignUpModalContext.Provider>
  );
};

export const useAuthDialog = () => {
  const context = useContext(SignUpModalContext);
  if (!context) {
    throw new Error('useAuthDialog must be used within a AuthDialogProvider');
  }
  return context;
};
