import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    RUN_BUNDLE_ANALYZER: z
      .string()
      // transform to boolean using preferred coercion logic
      .transform((s) => s !== 'false' && s !== '0'),
  },
  client: {
    NEXT_PUBLIC_STRAPI_URL: z.string().url(),
    NEXT_PUBLIC_CDN_URL: z.string().url(),
    NEXT_PUBLIC_API_URL: z.string().url(),
    NEXT_PUBLIC_API_FALLBACK_URL: z.string().url(),
  },
  // If you're using Next.js < 13.4.4, you'll need to specify the runtimeEnv manually
  runtimeEnv: {
    NEXT_PUBLIC_STRAPI_URL: process.env.NEXT_PUBLIC_STRAPI_URL,
    NEXT_PUBLIC_CDN_URL: process.env.NEXT_PUBLIC_CDN_URL,
    NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_API_FALLBACK_URL: process.env.NEXT_PUBLIC_API_FALLBACK_URL,
    RUN_BUNDLE_ANALYZER: process.env.RUN_BUNDLE_ANALYZER,
  },
  // For Next.js >= 13.4.4, you only need to destructure client variables:
  // experimental__runtimeEnv: {
  //   NEXT_PUBLIC_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_PUBLISHABLE_KEY,
  // }
});
