import { Game } from '@/enums';

export function mapGameFilterToVideoGameName(filter: Game): string | undefined {
  switch (filter) {
    case Game.CSGO:
      return 'Counter-Strike';
    case Game.DOTA:
      return 'Dota 2';
    default:
      return undefined;
  }
}
