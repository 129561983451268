import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { GoCoreLogo } from '../logo';
import { generateHrefToNavigateTo } from './Navigation/navigation.utils';

interface LogoProps {
  className?: string;
}

const Logo = ({ className }: LogoProps) => {
  const pathname = usePathname();
  const targetHref = '/';

  const href = generateHrefToNavigateTo(pathname, targetHref, true);

  return (
    <Link href={href}>
      <GoCoreLogo className={className} />
    </Link>
  );
};

export default Logo;
