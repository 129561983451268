/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';
import { Button } from '@/components/button';
import Bitcoin from '@public/icons/bitcoin.svg';
import Dollar from '@public/icons/dollar.svg';
import LogoPiece from '@public/icons/logo_piece-2.svg';
import Pages from '@public/icons/pages.svg';
import DOMPurify from 'dompurify';
import { parse } from 'marked';
import Image from 'next/image';
import { FC } from 'react';
import './styles.css';

interface IBookmakerCard {
  data: any;
}

const BookmakerCard: FC<IBookmakerCard> = ({ data }) => {
  const {
    BookmakerUrl,
    Code,
    OurReviewList,
    BonusOffer,
    BookmakerLogo,
    Slug,
    BookmakerName,
    PaymentType,
  } = data.attributes;
  const detailArray = Object.values(OurReviewList ?? {});

  const imageUrl = BookmakerLogo?.data?.attributes?.url;

  return (
    <div className="flex flex-col gap-4 md:flex-row bg-light-gray p-4 md:p-[0.75rem] rounded-lg md:gap-[0.75rem] hover:bg-light-blue-o15 md:h-[189px]">
      <div className="flex flex-col gap-1">
        <div className="hidden md:block w-[133px] h-[72px] bg-black">
          <Image
            src={imageUrl}
            width={133}
            height={0}
            className="rounded-lg h-full"
            alt="bookmaker-logo"
          />
        </div>
        <Image
          src={imageUrl}
          width={294}
          height={104}
          className=" md:hidden w-full rounded-lg "
          alt="bookmaker-logo"
        />
        <div className="hidden md:flex px-1 bg-card-bg rounded-lg gap-0.5 flex-row justify-end py-[17.5px] md:py-[14.5px] items-start	">
          {/* <p className="font-normal	text-gray text-[0.6rem] leading-[0.6rem] inline-block	">
            Accepted payments
          </p>
          <div className="flex flex-row gap-2">
            {PaymentType === "All" ? (
              <>
                <Dollar />
                <Bitcoin />
              </>
            ) : PaymentType === "Crypto" ? (
              <Bitcoin />
            ) : (
              <Dollar />
            )}
          </div> */}
          <div className="w-[20px] h-[20px]">
            <Pages className="text-blue-gray " />
          </div>
          <div
            onClick={() => navigator.clipboard.writeText(Code)}
            className="text-xs leading-5 font-normal text-gray cursor-pointer w-[103px] "
          >
            Copy code and get a additional welcome gift
          </div>
        </div>
      </div>
      <div className="md:bg-custom-gradient-2 rounded-lg md:p-2 md:w-[11.313rem] md:h-[10.313rem] ">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row md:flex-col gap-1 md:gap-2">
            <div className="relative flex flex-col bg-light-blue-o15 rounded-lg p-2 w-[165px] h-[96px] overflow-hidden ">
              <p className="font-normal text-[10px] leading-4">
                {BookmakerName} offer
              </p>
              <article className="line-clamp-3">
                <div className="font-semibold text-[18px] leading-5">
                  <div
                    className="flex flex-col gap-2 "
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        parse(BonusOffer ?? '') as string,
                        {
                          USE_PROFILES: { html: true },
                        }
                      ),
                    }}
                  />
                </div>
              </article>
              <div
                className="absolute bottom-0 right-0 w-[46px] h-[38px] bg-no-repeat bg-contain "
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
              >
                <LogoPiece />
              </div>
            </div>

            <div className="flex md:hidden px-1 bg-card-bg rounded-lg gap-0.5 flex-row justify-end py-[17.5px] items-start	">
              <div className="w-[20px] h-[20px]">
                <Pages className="text-blue-gray " />
              </div>
              <div
                onClick={() => navigator.clipboard.writeText(Code)}
                className="text-xs leading-5 font-normal text-gray cursor-pointer w-[103px] "
              >
                Copy code and get a additional welcome gift
              </div>
            </div>
            <div className="md:flex hidden py-2 px-4 bg-dark-gray rounded-lg gap-1 flex-1 flex-col justify-center">
              <p className="font-normal	text-gray text-[0.6rem] leading-[0.6rem] inline-block	">
                Accepted payments
              </p>
              <div className="flex flex-row gap-2">
                {PaymentType === 'All' ? (
                  <>
                    <Dollar />
                    <Bitcoin />
                  </>
                ) : PaymentType === 'Crypto' ? (
                  <Bitcoin />
                ) : (
                  <Dollar />
                )}
              </div>
            </div>
          </div>
          <div className="md:hidden py-2 px-4 bg-dark-gray rounded-lg gap-1 flex-1 flex flex-col justify-center">
            <p className="font-normal	text-gray text-[0.6rem] leading-[0.6rem] inline-block	">
              Accepted payments
            </p>
            <div className="flex flex-row gap-2">
              {PaymentType === 'All' ? (
                <>
                  <Dollar />
                  <Bitcoin />
                </>
              ) : PaymentType === 'Crypto' ? (
                <Bitcoin />
              ) : (
                <Dollar />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between flex-1 max-md:gap-4">
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-gray text-sm leading-4">
            Our review
          </p>
          <ul className="list-disc ml-6 md:ml-5">
            {detailArray ? (
              detailArray.slice(0, 5).map((i: any, index) => {
                return (
                  <li
                    key={index}
                    className="font-normal text-xs leading-5 text-white"
                  >
                    {i}
                  </li>
                );
              })
            ) : (
              <></>
            )}
          </ul>
        </div>
        <div className="grid grid-cols-2 items-center gap-3">
          <Button href={`/hub/${Slug}`} size="sm" as="link" variant="ghost">
            View review
          </Button>
          <Button
            href={BookmakerUrl ?? ''}
            target="_blank"
            size="sm"
            as="link"
            variant="primary"
          >
            Bet Now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BookmakerCard;
