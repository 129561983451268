'use client';

import { useGetApiRssFeed } from '@/api/backoffice/rss/rss';
import NewsFeedCard from '@/components/cards/newsFeedCard';
import SkeletonNews from '@/components/skeletons/rss';
import { Game } from '@/enums';
import { cn } from '@/utils/cn';
import type { HTMLAttributes } from 'react';

export type NewsFeedBannerProps = HTMLAttributes<HTMLDivElement> & {
  game?: Game;
};

export const NewsFeedBanner = ({
  className,
  game,
  ...props
}: NewsFeedBannerProps) => {
  const { data, isLoading } = useGetApiRssFeed();

  return (
    <div
      className={cn(
        'flex w-full flex-col bg-medium-gray gap-4 p-4 rounded-lg',
        className
      )}
      {...props}
    >
      <div className="flex flex-row justify-start items-center">
        <p className="font-semibold text-base">News Feed</p>
      </div>
      {isLoading && (
        <>
          {Array.from({ length: 10 }, (_, index) => (
            <SkeletonNews key={index} />
          ))}
        </>
      )}

      {data?.data
        .filter(
          (item) =>
            item.game ===
            (game === Game.CSGO
              ? 'CS'
              : game === Game.DOTA
                ? 'Dota'
                : item.game)
        )
        .sort(
          (a, b) =>
            new Date(b.date as string).getTime() -
            new Date(a.date as string).getTime()
        )
        .slice(0, 10)
        .map((item, index: number) => <NewsFeedCard key={index} data={item} />)}
    </div>
  );
};
