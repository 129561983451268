import { MatchDetailDto, TeamMatchDetailDto } from '@/api/backoffice-schemas';
import { cn } from '@/utils/cn';
import { getGameFromStrapiVideoGame } from '@/utils/getGameFromStrapiGame';
import Check from '@public/icons/check.svg';
import CsIcon from '@public/icons/games/cs.svg';
import DotaIcon from '@public/icons/games/dota.svg';
import LolIcon from '@public/icons/games/lol.svg';
import TeamLogoPlaceholder from '@public/icons/teams/team-placeholder.svg';
import type { StrapiVideoGame } from '@t/strapi';
import moment from 'moment-timezone';
import Image from 'next/image';
import Link from 'next/link';

interface IEventCardProps {
  data: MatchDetailDto;
}

const EventCard = (props: IEventCardProps) => {
  if (!props.data) {
    return null;
  }

  const {
    teamMatches,
    numberOfGames,
    matchType,
    league,
    videoGame,
    beginAt,
    id,
    status,
    draw,
  } = props.data;

  const team1 = teamMatches?.[0];
  const team2 = teamMatches?.[1];

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  enum MatchType {
    'best_of' = 'Best of',
    'first_to' = 'First to',
    'red_bull_home_ground' = 'Redbull best of',
  }

  const matchTypeLabel = `${
    MatchType[matchType as keyof typeof MatchType]
  } ${numberOfGames}`;

  const leagueLabel = league?.name;

  const Score = ({
    isWinner = false,
    teamMatch,
  }: {
    teamMatch: TeamMatchDetailDto;
    isWinner: boolean;
  }) => {
    return (
      <div
        className={cn(
          'px-3 flex justify-center rounded',
          status === 'finished'
            ? isWinner
              ? 'bg-green-o20'
              : 'bg-red-o20'
            : 'bg-white-o20'
        )}
      >
        {status === 'finished' ? (draw ? '-' : (teamMatch.score ?? 0)) : '-'}
      </div>
    );
  };

  const getIcon = () => {
    switch (videoGame?.slug) {
      case 'lol':
        return <LolIcon className="text-cyan w-4" />;
      case 'cs-go':
        return <CsIcon className="text-orange w-4" />;
      case 'dota-2':
        return <DotaIcon className="text-red w-4" />;
      default:
        return null;
    }
  };

  const getGameBanner = () => {
    return (
      <div className="h-[54px] w-[54px] flex items-center justify-center bg-[#0C171E80] rounded-lg px-2 flex-col gap-1">
        {getIcon()}
        <div
          className={cn(
            status === 'finished' ? 'opacity-50' : '',
            'text-gray font-medium text-[11px] leading-[14px]'
          )}
        >
          {status === 'finished'
            ? 'Finished'
            : `${moment.utc(beginAt).tz(userTimeZone).format('HH:mm')}`}
        </div>
      </div>
    );
  };

  const TeamDisplay = ({
    teamMatch,
    isWinner = false,
  }: {
    teamMatch: TeamMatchDetailDto;
    isWinner: boolean;
  }) => {
    return (
      <div className="flex flex-row gap-2 items-center content-center">
        {teamMatch.team?.imageUrl ? (
          <Image
            src={teamMatch.team.imageUrl}
            alt={teamMatch.team.name || 'Team logo'}
            width={20}
            height={20}
          />
        ) : (
          <TeamLogoPlaceholder />
        )}
        <div className="flex flex-row gap-1 md:gap-2 items-center">
          <p className="hidden md:block font-medium text-[0.875rem] leading-[1.125rem] overflow-hidden text-ellipsis">
            {teamMatch.team?.name || '(TBD)'}
          </p>
          <p className="md:hidden font-medium text-[0.875rem] leading-[1.125rem]">
            {teamMatch.team?.acronym ?? teamMatch.team?.name ?? '(TBD)'}
          </p>
          {isWinner && status === 'finished' && !draw ? (
            <Check className="text-green" />
          ) : null}
        </div>
      </div>
    );
  };

  const Teams = () => {
    const isTeam1Winner =
      status === 'finished' &&
      !draw &&
      props.data.winner?.externalId === team1?.team?.externalId;
    const isTeam2Winner =
      status === 'finished' &&
      !draw &&
      props.data.winner?.externalId === team2?.team?.externalId;

    return (
      <div className="flex flex-col w-[9.813rem] md:w-[12.563rem] gap-2">
        {team1 && <TeamDisplay teamMatch={team1} isWinner={isTeam1Winner} />}
        {team2 && <TeamDisplay teamMatch={team2} isWinner={isTeam2Winner} />}
      </div>
    );
  };

  return (
    <Link
      href={`/${getGameFromStrapiVideoGame(videoGame?.slug as StrapiVideoGame)}/matches/${id}`}
      className="flex flex-row items-center justify-between p-[0.75rem] gap-2 md:gap-[0.75rem] rounded-lg bg-light-gray hover:bg-light-blue-o15"
    >
      {getGameBanner()}
      <Teams />
      <div className="flex flex-col items-start justify-around w-[82px] h-full">
        <p className="font-semibold text-[11px] leading-[12px]">
          {matchTypeLabel}
        </p>
        <p className="font-normal text-[10px] leading-[12px]">{leagueLabel}</p>
      </div>
      <div className="grid grid-cols-1 grid-rows-2 gap-2">
        {team1 && (
          <Score
            isWinner={
              status === 'finished' &&
              !draw &&
              props.data.winner?.externalId === team1?.team?.externalId
            }
            teamMatch={team1}
          />
        )}
        {team2 && (
          <Score
            isWinner={
              status === 'finished' &&
              !draw &&
              props.data.winner?.externalId === team2?.team?.externalId
            }
            teamMatch={team2}
          />
        )}
      </div>
    </Link>
  );
};

export default EventCard;
