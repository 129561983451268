import { env } from '@/config/env';
import { swrSingleGetConfig } from '@/configs/swrConfig';
import useSWR from 'swr';

// TODO: in the future after adding pagination , add filtering functionality using
type BookmakersFilterOptions = {
  IsFeatured?: boolean;
  IsTopPick?: boolean;
};

const useGetBookmakers = ({
  IsFeatured,
  IsTopPick,
}: BookmakersFilterOptions = {}) => {
  const fetcher = async (url: string) => {
    const response = await fetch(url, {
      method: 'GET',
    });

    return await response.json();
  };

  const getFilterQuery = () => {
    const filters: string[] = [];

    if (IsFeatured !== undefined) {
      filters.push(`IsFeatured=${IsFeatured}`);
    }
    if (IsTopPick !== undefined) {
      filters.push(`IsTopPick=${IsTopPick}`);
    }

    return filters.length ? `&${filters.join('&')}` : '';
  };

  const { data, mutate, isLoading } = useSWR(
    `${
      env.NEXT_PUBLIC_STRAPI_URL
    }/bookmaker-banners?populate=*${getFilterQuery()}`,
    fetcher,
    swrSingleGetConfig
  );

  // // Build the base URL
  // let url = `https://strapi.gocore.gg/api/bookmaker-banners?populate=*`;

  // // Conditionally add the `IsFeatured` filter if provided
  // if (typeof isHighlighted !== "undefined") {
  //   url += `&filters[IsHighlighted][$eq]=${isHighlighted}`;
  // }

  // const { data, mutate, isLoading } = useSWR(url, fetcher, swrSingleGetConfig);

  return { data, isLoading, mutate };
};

export default useGetBookmakers;
// IsHighlighted;
